.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #339999;
  color: #fff;
}
::selection {
  background: #339999;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(51, 153, 153, 0.1);
  box-sizing: border-box;
}
main {
  display: block;
}
html {
  font-size: 95%;
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #000000;
  font-family: "sourcesans", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 160rem;
  overflow-x: hidden;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1.125rem;
  }
}
body.no_scroll {
  overflow: visible;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1 {
  letter-spacing: calc((1.5rem + 0.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.5rem;
  color: #000000;
  font-family: "sourcesans", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 3.125rem 0 0.75em;
  text-transform: uppercase;
}
@media (min-width: 20rem) {
  h1 {
    font-size: calc(1.5rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1 {
    font-size: 1.625rem;
  }
}
h2 {
  letter-spacing: calc((1.375rem + 0.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.375rem;
  color: #000000;
  font-family: "sourcesans", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h2 {
    font-size: calc(1.375rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2 {
    font-size: 1.5rem;
  }
}
h3 {
  letter-spacing: calc((1rem + 0.375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #000000;
  font-family: "sourcesans", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3 {
    font-size: calc(1rem + 0.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3 {
    font-size: 1.375rem;
  }
}
h4 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #000000;
  font-family: "sourcesans", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h4 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4 {
    font-size: 1rem;
  }
}
h5 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #000000;
  font-family: "sourcesans", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5 {
    font-size: 1rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #000000;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #000000;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(51, 153, 153, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #000000;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #339999;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #000000;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(51, 153, 153, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.0625rem solid #b3b3b3;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #b3b3b3;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #cccccc;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #339999;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg") !important;
  margin: 0 0 0.75em;
  padding-left: 1.125em;
  -webkit-transition: list-style 300ms;
  transition: list-style 300ms;
}
ul > li {
  padding-left: 0.375em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid #b3b3b3;
  margin: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid #b3b3b3;
  font-weight: 700;
  padding: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.0625rem solid #b3b3b3;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none!important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 400;
  letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 0.875rem;
}
@media (min-width: 20rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1.125rem;
  }
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000000;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #404040;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #404040;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #404040;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #339999;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #339999;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
fieldset .entry {
  position: relative;
}
fieldset .entry .label {
  height: 100%;
  position: absolute;
  z-index: 9;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #000000;
  font-family: "sourcesans", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: none;
  margin-bottom: 0.375em;
}
.show_label .label {
  display: inline-block;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #b3b3b3;
  border-radius: 0;
  color: #000000;
  font-size: 95%;
  margin: 0 0 0.75em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #339999;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #339999;
  color: #000000;
}
.input:focus,
.textarea:focus {
  border-color: #339999;
  color: #000000;
}
.input {
  padding: 0 1.5em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  height: 2.39130435rem;
}
@media (min-width: 20rem) {
  .input {
    height: calc(2.3913043478260874rem + 0.543478260869565
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .input {
    height: 2.93478261rem;
  }
}
.textarea {
  line-height: 1.5;
  overflow: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0 0.75em 0.75em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #339999;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #339999;
  color: #000000;
}
.control input:focus ~ .indicator {
  border-color: #339999;
  color: #000000;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #b3b3b3;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #000000;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #b3b3b3;
  border-radius: 0;
  color: #000000;
  cursor: pointer;
  display: block;
  font-size: 95%;
  margin: 0 0 0.75em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #000000;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #339999;
}
.select.error.focus {
  border-color: #339999;
  color: #000000;
}
.select.focus {
  border-color: #339999;
  color: #000000;
}
.select select {
  padding: .6em 0;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #000000;
  cursor: pointer;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 2.39130435rem;
}
@media (min-width: 20rem) {
  select {
    min-height: calc(2.3913043478260874rem + 0.543478260869565
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select {
    min-height: 2.93478261rem;
  }
}
select option {
  min-height: 2.39130435rem;
}
@media (min-width: 20rem) {
  select option {
    min-height: calc(2.3913043478260874rem + 0.543478260869565
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 2.93478261rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
}
select:focus::-ms-value {
  background: none;
  color: #000000;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(51, 153, 153, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(51, 153, 153, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(51, 153, 153, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(51, 153, 153, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 1.5em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #b3b3b3;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 0.5em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #339999;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #339999;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #339999;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #339999;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.25em;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .address .remove_padding_from_headline {
    padding-top: 0;
  }
  .address .company_name .addition {
    letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.875rem;
    display: block;
    font-weight: 400;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact > span:first-child {
    margin-right: 0.3125rem;
  }
  .contact > span:first-child:after {
    content: ":";
  }
  .address_vcard_button {
    margin: 0.75em 0;
  }
  .address_call_button {
    margin: 0.75em 0;
  }
  aside figure {
    margin-bottom: 0;
  }
  aside * {
    color: #fff;
  }
  aside .sub_menu {
    margin-bottom: 0.75em;
  }
  aside .steuernews_article_overview_widget {
    padding: 2.25em 0;
  }
  aside .steuernews_article_overview_widget .creation_date {
    display: none;
  }
  aside .steuernews_article_overview_widget ul,
  aside .steuernews_article_overview_widget .entry {
    list-style: none;
    padding: 0;
  }
  aside .steuernews_issues .title,
  aside .steuernews_current_issue .title,
  aside .steuernews_article_overview_widget .title,
  aside .teaser_overview .teaser > a .title,
  aside .steuernews_issues h2,
  aside .steuernews_current_issue h2,
  aside .steuernews_article_overview_widget h2,
  aside .teaser_overview .teaser > a h2 {
    letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 1.5em;
    padding: 0 2.25em;
    position: relative;
    text-transform: uppercase;
  }
  aside .steuernews_issues .title:after,
  aside .steuernews_current_issue .title:after,
  aside .steuernews_article_overview_widget .title:after,
  aside .teaser_overview .teaser > a .title:after,
  aside .steuernews_issues h2:after,
  aside .steuernews_current_issue h2:after,
  aside .steuernews_article_overview_widget h2:after,
  aside .teaser_overview .teaser > a h2:after {
    background-color: #fff;
    bottom: -0.6em;
    content: "";
    display: block;
    height: 0.3125rem;
    position: absolute;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 0.9375rem;
    z-index: 9;
  }
  aside .download_overview {
    background-color: #339999;
  }
  aside .teaser_overview {
    margin-bottom: 0.75em;
  }
  aside .teaser_overview .teaser:first-child > a {
    padding-top: 2.25em;
  }
  aside .teaser_overview .teaser:last-child > a {
    padding-bottom: 2.25em;
  }
  aside .teaser_overview .teaser > a {
    background-color: #727478;
    display: block;
    padding: 1em 2.25em 1.5em 2.25em;
    text-decoration: none;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  aside .teaser_overview .teaser > a:hover,
  aside .teaser_overview .teaser > a.hover,
  aside .teaser_overview .teaser > a:focus,
  aside .teaser_overview .teaser > a:active,
  aside .teaser_overview .teaser > a.active {
    background-color: #fff;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  aside .teaser_overview .teaser > a:hover .title,
  aside .teaser_overview .teaser > a.hover .title,
  aside .teaser_overview .teaser > a:focus .title,
  aside .teaser_overview .teaser > a:active .title,
  aside .teaser_overview .teaser > a.active .title {
    color: #000000;
  }
  aside .teaser_overview .teaser > a:hover .title:after,
  aside .teaser_overview .teaser > a.hover .title:after,
  aside .teaser_overview .teaser > a:focus .title:after,
  aside .teaser_overview .teaser > a:active .title:after,
  aside .teaser_overview .teaser > a.active .title:after {
    background-color: #000000;
  }
  aside .teaser_overview .teaser > a .title {
    margin: 0;
    padding: 0;
  }
  aside .teaser_overview .teaser > a .icon {
    display: none;
  }
  aside .teaser_overview .teaser > a .text {
    display: none;
  }
  .steuernews_current_issue ul li .active:before {
    background-color: #000000;
    content: "";
    display: block;
    height: 0.3125rem;
    left: 1em;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 0.9375rem;
    z-index: 9;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_hor {
    display: block;
    fill: #fff;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.5em;
    width: 4.6875rem;
  }
  .breadcrumbs {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.75em;
  }
  .breadcrumbs p {
    margin: 0 0.375em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.375em;
  }
  .button {
    border-radius: 0;
    padding: 0 1.5em;
    background: #339999;
    border: 0.0625rem solid #fff;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .button .icon + .text {
    padding-left: 0.5em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(51, 153, 153, 0.2);
    outline: 0;
    background: #40bfbf;
    border-color: #40bfbf;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #40bfbf;
    border-color: #40bfbf;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #339999 inset;
    background: #339999;
    border: 0.0625rem solid #339999;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #339999;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #339999;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  .chart_organigram {
    display: none;
  }
  .google-visualization-orgchart-table {
    border-bottom: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google-visualization-orgchart-table .node {
    background: #cccccc;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 85%;
    padding: 0.4375rem;
    vertical-align: middle;
  }
  .google-visualization-orgchart-table .google-visualization-orgchart-linenode {
    border-color: #b3b3b3;
    border-top: 0;
  }
  .company_logo_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .company_logo_wrapper .company_logo_div {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    margin-right: 1em;
  }
  .company_logo_wrapper .company_logo_div .company_logo {
    display: block;
    -webkit-transition: fill 300ms, stroke 300ms, width 300ms ease-out;
    transition: fill 300ms, stroke 300ms, width 300ms ease-out;
    width: 4.375rem;
  }
  .company_logo_wrapper .company_logo_div .company_logo:hover {
    fill: #339999;
    stroke: #339999;
    -webkit-transition: fill 300ms, stroke 300ms, width 300ms ease-out;
    transition: fill 300ms, stroke 300ms, width 300ms ease-out;
  }
  .company_logo_wrapper .company_name_div {
    -webkit-box-flex: 1;
            flex-grow: 1;
    font-size: 0.875rem;
  }
  .company_logo_wrapper .company_name_div .company_name_connection {
    margin-top: 0.75em;
  }
  .company_logo_wrapper .company_name_div .company_name {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: end;
            justify-content: flex-end;
    margin-top: 0.75em;
  }
  .company_logo_wrapper .company_name_div .company_name a {
    text-decoration: none;
  }
  .company_logo_wrapper .company_name_div .company_name .name {
    font-size: 1rem;
    text-transform: uppercase;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #cccccc;
    border-top: 0.125rem solid #b3b3b3;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1em 0.75em 1em 1.5em;
  }
  .cookie_banner .close {
    cursor: pointer;
    flex-shrink: 0;
    margin: 0.75em;
    padding: 0 0.75em;
  }
  .cookie_banner .close > span {
    min-height: 2.75rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.3em;
  }
  .download_overview {
    margin-bottom: 0.75em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.375em;
    padding-top: 0;
  }
  .download_overview .icon {
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.75em;
    width: 1.5rem;
  }
  .download_overview .icon_doc {
    fill: #339999;
  }
  .download_overview .icon_xls {
    fill: #339999;
  }
  .download_overview .icon_pdf {
    fill: #339999;
  }
  .download_overview .icon_online {
    fill: #339999;
  }
  .dropdown_menu {
    display: none;
    margin-top: 2.08333333rem;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .dropdown_menu > ul li {
    position: relative;
  }
  .dropdown_menu > ul > li {
    height: 6.7375rem;
    width: 8.6625rem;
    -webkit-box-align: center;
            align-items: center;
    background-color: #727478;
    margin: 0 0.6em 0.6em 0;
    overflow: hidden;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .dropdown_menu > ul > li:last-child {
    margin-right: 0;
  }
  .dropdown_menu > ul > li:after {
    border-bottom: 33.125rem solid #339999;
    border-left: 11.25rem solid transparent;
    border-right: 11.25rem solid transparent;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: -webkit-transform 600ms;
    transition: -webkit-transform 600ms;
    transition: transform 600ms;
    transition: transform 600ms, -webkit-transform 600ms;
    width: 0;
    z-index: 8;
  }
  .dropdown_menu > ul > li:before {
    background-color: #fff;
    bottom: 1em;
    content: "";
    display: block;
    height: 0.3125rem;
    left: 0.75em;
    position: absolute;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 0.9375rem;
    z-index: 9;
  }
  .dropdown_menu > ul > li:active,
  .dropdown_menu > ul > li.active {
    background-color: #339999;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .dropdown_menu > ul > li:active:before,
  .dropdown_menu > ul > li.active:before {
    background-color: #000000;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .dropdown_menu > ul > li:active a,
  .dropdown_menu > ul > li.active a {
    color: #000000;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .dropdown_menu > ul > li.hover:after,
  .dropdown_menu > ul > li:hover:after {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .dropdown_menu > ul > li.hover:before,
  .dropdown_menu > ul > li:hover:before {
    background-color: #000000;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .dropdown_menu > ul > li.hover a,
  .dropdown_menu > ul > li:hover a {
    color: #000000;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: end;
            align-items: flex-end;
    color: #fff;
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    margin-right: 0.75em;
    padding: 1em 0.75em 1.5em 0.75em;
    position: relative;
    text-decoration: none;
    -webkit-transition: color 300ms;
    transition: color 300ms;
    white-space: nowrap;
    width: 100%;
    z-index: 10;
  }
  .dropdown_menu > ul > li ul {
    background: #cccccc;
    padding: 0.75em 0;
    position: absolute;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #000000;
    display: -webkit-box;
    display: flex;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 0 0.75em;
    white-space: nowrap;
    width: 100%;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    color: #339999;
  }
  .dropdown_menu > ul > li ul ul {
    left: 100%;
    position: absolute;
    top: -0.75em;
  }
  footer {
    background-color: #727478;
    margin-top: 4.6875rem;
    padding-bottom: 4.6875rem;
    padding-top: 4.6875rem;
    position: relative;
  }
  footer .contact_wrapper {
    margin-bottom: 1.5em;
  }
  footer * {
    color: #fff;
  }
  footer .container > div {
    position: relative;
    width: 100%;
  }
  footer .go_to_top {
    bottom: 102%;
    position: absolute;
    right: 0;
  }
  footer .go_to_top .icon {
    display: block;
    position: relative;
    width: 3.4375rem;
  }
  footer .go_to_top .icon:before {
    background-color: #339999;
    border-radius: 31.25rem;
    content: "";
    height: 6.25rem;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(0);
            transform: translateX(-50%) translateY(-50%) scale(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    width: 6.25rem;
  }
  footer .go_to_top .icon svg {
    fill: #727478;
    -webkit-transform: scale(1) rotate(-90deg);
            transform: scale(1) rotate(-90deg);
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  footer .go_to_top:hover .icon svg,
  footer .go_to_top.hover .icon svg,
  footer .go_to_top:focus .icon svg {
    fill: #fff;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  footer .go_to_top:hover .icon:before,
  footer .go_to_top.hover .icon:before,
  footer .go_to_top:focus .icon:before {
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
            transform: translateX(-50%) translateY(-50%) scale(1);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  footer .sitemap .confirm_page,
  footer .sitemap .news_entity,
  footer .sitemap .zms_folder {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  footer .sitemap .confirm_page ul,
  footer .sitemap .news_entity ul,
  footer .sitemap .zms_folder ul {
    list-style: none!important;
    padding: 0;
  }
  footer .sitemap .confirm_page ul li,
  footer .sitemap .news_entity ul li,
  footer .sitemap .zms_folder ul li {
    padding: 0;
  }
  footer .sitemap .confirm_page ul li a,
  footer .sitemap .news_entity ul li a,
  footer .sitemap .zms_folder ul li a {
    display: -webkit-box;
    display: flex;
    height: 100%;
    text-decoration: none;
    width: 100%;
  }
  footer .sitemap .confirm_page ul li a:hover,
  footer .sitemap .news_entity ul li a:hover,
  footer .sitemap .zms_folder ul li a:hover {
    color: #000000;
  }
  footer .sitemap .confirm_page ul li a.active,
  footer .sitemap .news_entity ul li a.active,
  footer .sitemap .zms_folder ul li a.active {
    color: #fff;
    text-decoration: underline;
  }
  footer .sitemap .confirm_page .wrapper_two .entry,
  footer .sitemap .news_entity .wrapper_two .entry,
  footer .sitemap .zms_folder .wrapper_two .entry {
    display: inline-block;
    width: 49%;
  }
  footer .sitemap .confirm_page .wrapper_one,
  footer .sitemap .news_entity .wrapper_one,
  footer .sitemap .zms_folder .wrapper_one {
    display: -webkit-box;
    display: flex;
  }
  footer .sitemap .confirm_page .wrapper_one .entry,
  footer .sitemap .news_entity .wrapper_one .entry,
  footer .sitemap .zms_folder .wrapper_one .entry {
    flex-basis: 50%;
  }
  footer .company_name_connection {
    margin-bottom: 0.5em;
  }
  footer .company_name {
    display: block;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  footer .company_name .addition {
    text-transform: none;
  }
  footer .company_name,
  footer .bold {
    font-weight: 700;
  }
  .form_disclaimer {
    background: #f2f2f2;
    border-radius: 0;
    color: #000000;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .file {
    height: 2.39130435rem;
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    font-size: 95%;
    margin: 0 0 1em;
    position: relative;
  }
  .file.error .file_name {
    border-color: #339999;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #b3b3b3;
    border-radius: 0 0 0 0;
    color: #000000;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    overflow: hidden;
    padding: 0 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
    line-height: 2.625rem;
    height: 2.39130435rem;
  }
  .file .file_button {
    -webkit-box-align: center;
            align-items: center;
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.5em;
    z-index: 10;
    background: #339999;
    border: 0.0625rem solid #fff;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .file .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .file .file_button .icon + .text {
    padding-left: 0.5em;
  }
  .file .file_button.focus {
    background: #40bfbf;
    border-color: #40bfbf;
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
    height: 2.39130435rem;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #40bfbf;
    border-color: #40bfbf;
    color: #fff;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    background: #339999;
    border: 0.0625rem solid #339999;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.5em;
  }
  button {
    cursor: pointer;
    margin: 0.75em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.5em;
    width: 100%;
    height: 2.75rem;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .google_maps {
    margin: 0 0 0.75em;
  }
  .google_maps .map {
    background: #cccccc;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.75em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #339999;
    border-color: #339999;
    color: #fff;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(51, 153, 153, 0.2);
    z-index: 15;
    background: #40bfbf;
    border-color: #40bfbf;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #b3b3b3;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.5em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000000;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #339999;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .google_maps .direction {
    margin: 0.75em 0;
  }
  .google_maps .button_wrapper .button {
    margin: 0;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.75em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #b3b3b3;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #b3b3b3;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.75em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.mobile .container {
      padding-left: max(0.9375rem, env(safe-area-inset-left));
      padding-right: max(0.9375rem, env(safe-area-inset-right));
    }
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.15625rem;
    margin-right: -0.15625rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.15625rem;
    padding-right: 0.15625rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  header {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  header .fixed_header {
    left: 0;
    margin: 0 auto;
    max-width: 160rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
  }
  header .fixed_header:after {
    background-color: #cccccc;
    border: 0;
    bottom: 0;
    content: "";
    height: 0.125rem;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    -webkit-transition: opacity 300ms ease-out;
    transition: opacity 300ms ease-out;
  }
  header .fixed_header.scrolled_down:after {
    opacity: 1;
  }
  header .fixed_header .top_header_wrapper {
    background-color: #339999;
    position: relative;
  }
  header .fixed_header .top_header_wrapper .top_header {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  header .fixed_header .top_header_wrapper .top_header .header_icons {
    align-self: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  header .fixed_header .top_header_wrapper .top_header .call,
  header .fixed_header .top_header_wrapper .top_header .fax,
  header .fixed_header .top_header_wrapper .top_header .email {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin-right: 1.5em;
  }
  header .fixed_header .top_header_wrapper .top_header .call .icon,
  header .fixed_header .top_header_wrapper .top_header .fax .icon,
  header .fixed_header .top_header_wrapper .top_header .email .icon {
    display: block;
    fill: #fff;
    height: 1.5625rem;
    margin-right: 0.5em;
    width: 1.5625rem;
  }
  header .fixed_header .top_header_wrapper .top_header .call .text,
  header .fixed_header .top_header_wrapper .top_header .fax .text,
  header .fixed_header .top_header_wrapper .top_header .email .text {
    color: #fff;
  }
  header .fixed_header .top_header_wrapper .top_header .call .text,
  header .fixed_header .top_header_wrapper .top_header .email .text {
    display: none;
  }
  header .fixed_header .bottom_header_wrapper {
    background-color: #fff;
    padding: 1.25rem 0;
  }
  header .scrolled_header {
    margin-top: 13.1875rem;
  }
  header .mobile_menu {
    -webkit-box-align: center;
            align-items: center;
    background-color: #727478;
    display: -webkit-box;
    display: flex;
    height: 4.8125rem;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-top: 1em;
    position: relative;
  }
  header .mobile_menu > .toggle_offcanvas_menu,
  header .mobile_menu > .call {
    -webkit-box-flex: 1;
            flex: 1;
  }
  header .mobile_menu:before,
  header .mobile_menu:after {
    background-color: #727478;
    content: "";
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }
  header .mobile_menu:before {
    left: calc(-50vw + 50%);
  }
  header .mobile_menu:after {
    right: calc(-50vw + 50%);
    z-index: -1;
  }
  header .mobile_menu .icon {
    height: 2.75rem;
    width: 2.75rem;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    padding: 0.3125rem;
    -webkit-transition: fill 300ms, background 300ms;
    transition: fill 300ms, background 300ms;
  }
  header .mobile_menu .icon:hover,
  header .mobile_menu .icon.hover,
  header .mobile_menu .icon:focus,
  header .mobile_menu .icon:active,
  header .mobile_menu .icon.active {
    fill: #000000;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  header .mobile_menu .icon:hover .line,
  header .mobile_menu .icon.hover .line,
  header .mobile_menu .icon:focus .line,
  header .mobile_menu .icon:active .line,
  header .mobile_menu .icon.active .line {
    background-color: #000000;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  main li a[href^="http"][target="_blank"]:not(.share_button)::after,
  main p:not(.form_disclaimer) a[href^="http"][target="_blank"]:not(.share_button)::after {
    background-image: url("../images/external_link.svg");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 0.875rem 0.875rem;
    content: "";
    display: inline-block;
    height: 1.25rem;
    padding-right: 1.25rem;
  }
  .image_gallery_overview {
    margin-bottom: 1.5em;
    position: relative;
  }
  .image_gallery_entry {
    background-color: #cccccc;
    border-bottom: 0.1875rem solid #fff;
    border-right: 0.1875rem solid #fff;
    display: block;
    position: relative;
  }
  .image_gallery_entry:focus,
  .image_gallery_entry:hover {
    outline: 0;
  }
  .image_gallery_entry:focus .open,
  .image_gallery_entry:hover .open {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_entry:focus .open svg,
  .image_gallery_entry:hover .open svg {
    -webkit-animation: news_photo 900ms;
            animation: news_photo 900ms;
  }
  .image_gallery_entry .open {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
    visibility: hidden;
  }
  .image_gallery_entry .open svg {
    height: 3.75rem;
    margin: auto;
    width: 3.75rem;
  }
  .image_gallery_entry .image {
    background-position: 50%;
    background-size: cover;
    height: 0;
    overflow: hidden;
    padding-bottom: 62.5%;
  }
  .image_gallery_overlay {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
  }
  .image_gallery_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .slider {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    white-space: nowrap;
    width: 100%;
  }
  .image_gallery_overlay .slider.bounce_from_right {
    -webkit-animation: image_gallery_bounce_from_right 300ms ease-out;
            animation: image_gallery_bounce_from_right 300ms ease-out;
  }
  .image_gallery_overlay .slider.bounce_from_left {
    -webkit-animation: image_gallery_bounce_from_left 300ms ease-out;
            animation: image_gallery_bounce_from_left 300ms ease-out;
  }
  .image_gallery_overlay .full_image {
    display: inline-block;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .image_gallery_overlay .full_image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 0;
  }
  .image_gallery_overlay .full_image figure {
    display: inline;
    height: 100%;
    margin: 0;
  }
  .image_gallery_overlay .full_image figcaption {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    color: #fff;
    display: block;
    padding: 0.75em;
    position: absolute;
    text-align: center;
    white-space: normal;
    width: 100%;
  }
  .image_gallery_overlay .full_image img {
    display: inline-block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    vertical-align: middle;
    visibility: hidden;
    width: auto;
  }
  .image_gallery_overlay .full_image img.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .spinner {
    display: inline-block;
    height: 1.125rem;
    left: 50%;
    margin-left: -2.1875rem;
    margin-top: -0.5625rem;
    position: absolute;
    top: 50%;
    width: 4.375rem;
  }
  .image_gallery_overlay .spinner .bounce {
    -webkit-animation: image_gallery_spinner 900ms infinite;
            animation: image_gallery_spinner 900ms infinite;
    background: #339999;
    border-radius: 100%;
    display: inline-block;
    height: 1.125rem;
    margin: 0 0.25rem;
    width: 1.125rem;
  }
  .image_gallery_overlay .spinner .bounce_1 {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
  }
  .image_gallery_overlay .spinner .bounce_2 {
    -webkit-animation-delay: -150ms;
            animation-delay: -150ms;
  }
  .image_gallery_overlay nav a {
    cursor: pointer;
    display: block;
    position: absolute;
  }
  .image_gallery_overlay nav .previous,
  .image_gallery_overlay nav .next {
    height: 2.75rem;
    margin-top: -1.375rem;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous:focus .icon,
  .image_gallery_overlay nav .next:focus .icon,
  .image_gallery_overlay nav .previous:hover .icon,
  .image_gallery_overlay nav .next:hover .icon {
    fill: #339999;
  }
  .image_gallery_overlay nav .previous .icon,
  .image_gallery_overlay nav .next .icon {
    fill: #fff;
    height: 2.75rem;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous {
    left: 2%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .image_gallery_overlay nav .next {
    right: 2%;
  }
  .image_gallery_overlay nav .close {
    background: #fff;
    height: 2.75rem;
    padding: 0.375rem;
    right: 0;
    top: 0;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .close:focus,
  .image_gallery_overlay nav .close:hover {
    outline: 0;
  }
  .image_gallery_overlay nav .close:focus .icon,
  .image_gallery_overlay nav .close:hover .icon {
    fill: #339999;
  }
  .image_gallery_overlay nav .close .icon {
    fill: #000000;
    height: 2rem;
    width: 2rem;
  }
  body.verbraucherpreisindizes .switch_view {
    cursor: pointer;
    margin-bottom: 0.75em;
  }
  .vpi_diagram_grid_wrapper {
    border: 0.0625rem solid #b3b3b3;
    margin-bottom: 1.5em;
    padding: 0.75em 1.5em 0.75em 0.75em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram {
    line-height: 1;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid_points_wrapper {
    position: relative;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_label_legend_wrapper {
    padding: 0.5em 0;
    position: relative;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_year_label {
    line-height: 1;
    margin: 0;
    padding: 0;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid {
    height: 18.75rem;
    padding: 0.75em 0 0.75em 0;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid line {
    stroke: #bbbbbb;
    stroke-width: 0.03125rem;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid text {
    text-anchor: end;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_points {
    height: 18.75rem;
    left: 0;
    padding: 0.75em 0 0.75em 3.75rem;
    position: absolute;
    right: 2.25em;
    top: 0;
    z-index: 1;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_points circle {
    cursor: pointer;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend circle,
  .vpi_diagram_grid_wrapper .vpi_diagram_points circle {
    fill: #339999;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend line,
  .vpi_diagram_grid_wrapper .vpi_diagram_points line {
    stroke: #339999;
    stroke-width: 0.125rem;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend {
    height: 2.5rem;
    margin-top: -1.25rem;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend text {
    text-anchor: end;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_months_wrapper {
    height: 3.125rem;
    margin-left: 3.75rem;
    position: relative;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_months {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_months text {
    text-anchor: middle;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output_wrapper {
    bottom: 0.75em;
    left: 0;
    margin-left: 3.75rem;
    position: absolute;
    right: 0;
    top: 0.75em;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    opacity: 0;
    padding: 1.05em;
    position: absolute;
    -webkit-transform: translateX(-50%) translateY(-120%);
            transform: translateX(-50%) translateY(-120%);
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    z-index: 2;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output.show {
    opacity: 1;
    visibility: visible;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output.right {
    -webkit-transform: translateX(-90%) translateY(-130%);
            transform: translateX(-90%) translateY(-130%);
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output .value {
    margin-bottom: 0;
    white-space: nowrap;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram text {
    font-size: 85%;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.75em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #339999;
    height: 3rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 3rem;
  }
  .disclaimer {
    background: #cccccc;
    border-radius: 0;
    color: #000000;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .infolist_download {
    -webkit-box-align: baseline;
            align-items: baseline;
    background: #cccccc;
    border-radius: 0;
    color: #000000;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download p {
    margin-right: 1.5em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #b3b3b3;
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 0.75em;
  }
  figure.small_full_width {
    margin-top: 0;
  }
  figure.no_margin {
    margin-right: 0;
    width: 100%;
  }
  .main_wrapper {
    overflow: hidden;
  }
  hr {
    background-color: #cccccc;
    border: 0;
    height: 0.125rem;
    margin-bottom: 3.125rem;
    margin-top: 4.6875rem;
  }
  .steuernews_article_overview_widget ul li a,
  .links_overview ul li a {
    display: -webkit-inline-box;
    display: inline-flex;
    height: 100%;
    padding: 0.25em 0;
    width: 100%;
  }
  .steuernews_article_overview_widget ul li:hover,
  .links_overview ul li:hover,
  .steuernews_article_overview_widget ul li.hover,
  .links_overview ul li.hover,
  .steuernews_article_overview_widget ul li:focus,
  .links_overview ul li:focus,
  .steuernews_article_overview_widget ul li.active,
  .links_overview ul li.active,
  .steuernews_article_overview_widget ul li:active,
  .links_overview ul li:active {
    -webkit-transition: list-style 300ms;
    transition: list-style 300ms;
  }
  .steuernews_article_overview_widget ul li:hover a,
  .links_overview ul li:hover a,
  .steuernews_article_overview_widget ul li.hover a,
  .links_overview ul li.hover a,
  .steuernews_article_overview_widget ul li:focus a,
  .links_overview ul li:focus a,
  .steuernews_article_overview_widget ul li.active a,
  .links_overview ul li.active a,
  .steuernews_article_overview_widget ul li:active a,
  .links_overview ul li:active a {
    color: #339999;
  }
  .steuernews_article_overview_widget ul li a,
  .links_overview ul li a {
    text-decoration: none;
  }
  .cyan_bg {
    background-color: #339999;
    margin-top: 4.6875rem;
    padding: 4.6875rem 0;
    position: relative;
  }
  .cyan_bg p::-moz-selection {
    background-color: #40bfbf;
  }
  .cyan_bg p::selection {
    background-color: #40bfbf;
  }
  .cyan_bg .head {
    margin-bottom: 1.5em;
  }
  .cyan_bg .head h2 {
    margin-bottom: 0;
  }
  .cyan_bg .button {
    margin: 0.375em 0;
    margin-bottom: 0.75em;
  }
  .cyan_bg .tooltip_wrapper textarea {
    height: 15rem;
    margin: 0;
  }
  .cyan_bg:before,
  .cyan_bg:after {
    background-color: #339999;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .cyan_bg:before {
    right: 100%;
  }
  .cyan_bg:after {
    left: 100%;
  }
  .cyan_bg * {
    color: #fff;
  }
  .cyan_bg .formular .input,
  .cyan_bg .formular .textarea {
    background-color: transparent;
    border: 0.0625rem solid #fff;
    color: #fff;
  }
  .cyan_bg .formular .input::-webkit-input-placeholder,
  .cyan_bg .formular .textarea::-webkit-input-placeholder {
    color: #fff;
  }
  .cyan_bg .formular .input:-ms-input-placeholder,
  .cyan_bg .formular .textarea:-ms-input-placeholder {
    color: #fff;
  }
  .cyan_bg .formular .input::placeholder,
  .cyan_bg .formular .textarea::placeholder {
    color: #fff;
  }
  .cyan_bg .formular .error_warning {
    background-color: #fff;
    color: #339999;
  }
  .cyan_bg .steuernews_article_overview_widget ul li:hover,
  .cyan_bg .links_overview ul li:hover,
  .cyan_bg .steuernews_article_overview_widget ul li.hover,
  .cyan_bg .links_overview ul li.hover,
  .cyan_bg .steuernews_article_overview_widget ul li:focus,
  .cyan_bg .links_overview ul li:focus,
  .cyan_bg .steuernews_article_overview_widget ul li.active,
  .cyan_bg .links_overview ul li.active,
  .cyan_bg .steuernews_article_overview_widget ul li:active,
  .cyan_bg .links_overview ul li:active {
    list-style: url("../images/list_style_image_rectangle.svg") !important;
    -webkit-transition: list-style 300ms;
    transition: list-style 300ms;
  }
  .cyan_bg .steuernews_article_overview_widget ul li:hover a,
  .cyan_bg .links_overview ul li:hover a,
  .cyan_bg .steuernews_article_overview_widget ul li.hover a,
  .cyan_bg .links_overview ul li.hover a,
  .cyan_bg .steuernews_article_overview_widget ul li:focus a,
  .cyan_bg .links_overview ul li:focus a,
  .cyan_bg .steuernews_article_overview_widget ul li.active a,
  .cyan_bg .links_overview ul li.active a,
  .cyan_bg .steuernews_article_overview_widget ul li:active a,
  .cyan_bg .links_overview ul li:active a {
    color: #000000;
  }
  .cyan_bg .success {
    bottom: 100%;
    fill: #339999;
    height: 11.375rem;
    margin-bottom: -0.625rem;
    position: absolute;
    right: 0;
    width: 8.75rem;
  }
  .breadcrumbs {
    display: none;
  }
  main,
  aside {
    margin-top: 1.5625rem;
  }
  .startpage main,
  .startpage aside {
    margin-top: 0;
  }
  .startpage footer {
    margin-top: 0;
  }
  .comment {
    letter-spacing: calc((0.75rem + 0.875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.75rem;
    display: none;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    margin: 4.5em 0;
    padding: 0 0.625rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
  }
  .comment p {
    margin: 0;
  }
  .comment:before,
  .comment:after {
    color: #727478;
    display: inline-block;
    font-size: 10rem;
    font-weight: 400;
    left: 0;
    position: absolute;
    top: 45%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .comment:before {
    content: "[";
    left: -2.5rem;
  }
  .comment:after {
    content: "]";
    left: calc(102.5%);
  }
  main figure {
    margin-top: 3em;
  }
  main .hideinmain {
    display: none;
  }
  .newsletter_quick_subscribe form {
    display: block;
  }
  .newsletter_quick_subscribe .entry {
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: #fff;
    border: 0.0625rem solid #b3b3b3;
    border-radius: 0;
    box-shadow: 0 0 0 #b3b3b3 inset;
    color: #000000;
    margin: 0;
    padding: 0 0.75em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms;
    width: 100%;
    height: 2.75rem;
  }
  .newsletter_quick_subscribe input[type="email"]:focus {
    background: #fff;
    border-color: #339999;
    box-shadow: 0 0 0.1875rem #339999 inset, 0 0 0 0.1875rem rgba(51, 153, 153, 0.2);
    color: #000000;
    outline: 0;
  }
  .newsletter_quick_subscribe button {
    background: #339999;
    border: 0.0625rem solid #fff;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0 1.5em;
    width: 100%;
  }
  .newsletter_quick_subscribe button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.625rem;
  }
  .newsletter_quick_subscribe button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .newsletter_quick_subscribe button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .newsletter_quick_subscribe button .icon + .text {
    padding-left: 0.5em;
  }
  .newsletter_quick_subscribe button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(51, 153, 153, 0.2);
    background: #40bfbf;
    border-color: #40bfbf;
    color: #fff;
  }
  .newsletter_quick_subscribe button:focus .icon {
    fill: #fff;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: block!important;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 600ms;
    transition: height 600ms;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 700;
    height: 2.75rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    min-height: 1.875rem;
    min-width: 1.625rem;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
  }
  .toggle_offcanvas_menu label .line {
    background: #fff;
    border-radius: 31.25rem;
    display: block;
    height: 0.3125rem;
    position: absolute;
    width: 80%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 15%;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 44%;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 15%;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    position: absolute;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transition: opacity 240ms, visibility 240ms, margin 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, margin 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, margin 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, margin 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 5000;
    right: 0;
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li:before {
    left: 0!important;
    top: 1.5em !important;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #fff;
    display: block;
    font-size: 112.5%;
    font-weight: 700;
    line-height: 2.75rem;
    padding: 0 1.5em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded > ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul li:before {
    left: 0!important;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    -webkit-box-align: center;
            align-items: center;
    color: #000000;
    display: -webkit-box;
    display: flex;
    height: 3em;
    padding: 0 1.5em 0 3em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul ul {
    border-bottom: 0.0625rem solid #b3b3b3;
    border-top: 0.0625rem solid #b3b3b3;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.75em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.75em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.375em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    -webkit-box-align: center;
            align-items: center;
    background: #339999;
    border-radius: 0 0 0 0;
    display: -webkit-box;
    display: flex;
    float: right;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    height: 2.75rem;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.2);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.75rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    background: #cccccc;
    cursor: move;
    height: 50vh;
    min-height: 25rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(204, 204, 204, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.5em 3em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.5em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.75em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.5em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #339999;
    border: 0.0625rem solid #fff;
    border-radius: 0;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #40bfbf;
    border: 0.0625rem solid #40bfbf;
    box-shadow: 0 0 0 0.1875rem rgba(51, 153, 153, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #40bfbf;
    border: 0.0625rem solid #40bfbf;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.875rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.75em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #cccccc;
    border-radius: 0;
    color: #000000;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .opening_times .title {
    padding-top: 0.75em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.25em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #b3b3b3;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .steuernews_article_slider {
    display: -webkit-box;
    display: flex;
    margin-bottom: 3.125rem;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
  }
  .steuernews_article_slider .slider {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    left: 0;
    position: absolute;
    -webkit-transition: -webkit-transform 600ms;
    transition: -webkit-transform 600ms;
    transition: transform 600ms;
    transition: transform 600ms, -webkit-transform 600ms;
  }
  .steuernews_article_slider .slide {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-shrink: 0;
    position: relative;
    width: 25%;
  }
  .steuernews_article_slider .slide .image {
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    max-width: 24.1875rem;
  }
  .steuernews_article_slider .slide .description {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    padding: 0 6em 0 4.5em;
  }
  .steuernews_article_slider .slide .description .title {
    margin: 0;
    padding-top: 0;
    word-wrap: break-word;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    margin-bottom: 0.75em;
  }
  .steuernews_article_slider .control_slides {
    -webkit-box-align: center;
            align-items: center;
    background: #cccccc;
    display: -webkit-box;
    display: flex;
    fill: #000000;
    height: 0;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: -1.875rem;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transition: background 300ms, opacity 300ms, visibility 300ms;
    transition: background 300ms, opacity 300ms, visibility 300ms;
    visibility: none;
    width: 0;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides.show {
    height: 3.75rem;
    opacity: 1;
    visibility: visible;
    width: 3.75rem;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    fill: #339999;
    outline: 0;
  }
  .steuernews_article_slider .control_slides .icon {
    height: 1.75rem;
    width: 1.75rem;
  }
  .steuernews_article_slider .prev_slide {
    left: -0.0625rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .steuernews_article_slider .next_slide {
    right: -0.0625rem;
  }
  .steuernews_navigation {
    margin: 1.5em 0;
  }
  .steuernews_navigation nav ul:after {
    clear: both;
  }
  .steuernews_navigation nav ul:after,
  .steuernews_navigation nav ul:before {
    content: "";
    display: table;
  }
  .steuernews_navigation nav li {
    float: left;
  }
  .steuernews_navigation nav li:first-child a {
    padding-left: 0;
  }
  .steuernews_navigation nav a {
    display: inline-block;
    line-height: 1.5;
    padding: 0 1.5em;
  }
  .steuernews_newsletter_subscribe {
    background: #cccccc;
    border-radius: 0;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_search {
    margin: 0 0 1.5em;
  }
  .steuernews_search .search {
    margin: 0 0 0.75em;
  }
  .steuernews_article_overview_widget ul {
    list-style: url("../images/list_style_image_rectangle_white.svg") !important;
  }
  .steuernews_preview {
    margin-bottom: 0.75em;
  }
  .steuernews_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.5em;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .steuernews_preview .image img {
    margin-right: 1.5em;
  }
  .steuernews_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .title {
    word-wrap: break-word;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    float: right;
    margin-left: 1.5em;
  }
  .news_image + * {
    padding-top: 0;
  }
  .hide_image_author {
    display: none;
  }
  .steuernews_issues h2 {
    color: #000000 !important;
  }
  .steuernews_issues h2:after {
    background-color: #000000 !important;
  }
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 0.375em;
    padding-left: 0;
  }
  .steuernews_issues ul li {
    flex-basis: 50%;
    margin-bottom: 0.375em;
    padding-left: 0;
  }
  .steuernews_issues ul li:nth-child(odd) a {
    border-right: 0.1875em solid #fff;
  }
  .steuernews_issues ul li:nth-child(even) a {
    border-left: 0.1875em solid #fff;
  }
  .steuernews_issues a {
    line-height: 2.75rem;
    background: #727478;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-transition: background 300ms, color 300ms;
    transition: background 300ms, color 300ms;
    white-space: nowrap;
    width: 100%;
  }
  .steuernews_issues a:focus,
  .steuernews_issues a:active,
  .steuernews_issues a.active,
  .steuernews_issues a:hover {
    background-color: #339999;
    color: #fff;
    -webkit-transition: background 300ms, color 300ms;
    transition: background 300ms, color 300ms;
  }
  .steuernews_sidebar {
    margin: 0;
  }
  .steuernews_issues h2,
  .steuernews_current_issue h2 {
    padding-top: 2.25em !important;
  }
  .offcanvas_menu,
  .steuernews_current_issue,
  aside .steuernews_article_overview_widget,
  .sub_menu {
    background-color: #339999;
    overflow: hidden;
    padding-bottom: 2.25em;
  }
  .offcanvas_menu > ul > li a,
  .steuernews_current_issue > ul > li a,
  aside .steuernews_article_overview_widget > ul > li a,
  .sub_menu > ul > li a {
    padding: 0 2.25em;
  }
  .offcanvas_menu ul li,
  .steuernews_current_issue ul li,
  aside .steuernews_article_overview_widget ul li,
  .sub_menu ul li {
    position: relative;
  }
  .offcanvas_menu ul li > ul .active > a,
  .steuernews_current_issue ul li > ul .active > a,
  aside .steuernews_article_overview_widget ul li > ul .active > a,
  .sub_menu ul li > ul .active > a,
  .offcanvas_menu ul li:active > a,
  .steuernews_current_issue ul li:active > a,
  aside .steuernews_article_overview_widget ul li:active > a,
  .sub_menu ul li:active > a,
  .offcanvas_menu ul li.active > a,
  .steuernews_current_issue ul li.active > a,
  aside .steuernews_article_overview_widget ul li.active > a,
  .sub_menu ul li.active > a,
  .offcanvas_menu ul li:focus > a,
  .steuernews_current_issue ul li:focus > a,
  aside .steuernews_article_overview_widget ul li:focus > a,
  .sub_menu ul li:focus > a,
  .offcanvas_menu ul li.hover > a,
  .steuernews_current_issue ul li.hover > a,
  aside .steuernews_article_overview_widget ul li.hover > a,
  .sub_menu ul li.hover > a,
  .offcanvas_menu ul li:hover > a,
  .steuernews_current_issue ul li:hover > a,
  aside .steuernews_article_overview_widget ul li:hover > a,
  .sub_menu ul li:hover > a {
    color: #000000;
  }
  .offcanvas_menu ul li > ul .active:before,
  .steuernews_current_issue ul li > ul .active:before,
  aside .steuernews_article_overview_widget ul li > ul .active:before,
  .sub_menu ul li > ul .active:before,
  .offcanvas_menu ul li:active:before,
  .steuernews_current_issue ul li:active:before,
  aside .steuernews_article_overview_widget ul li:active:before,
  .sub_menu ul li:active:before,
  .offcanvas_menu ul li.active:before,
  .steuernews_current_issue ul li.active:before,
  aside .steuernews_article_overview_widget ul li.active:before,
  .sub_menu ul li.active:before,
  .offcanvas_menu ul li:focus:before,
  .steuernews_current_issue ul li:focus:before,
  aside .steuernews_article_overview_widget ul li:focus:before,
  .sub_menu ul li:focus:before,
  .offcanvas_menu ul li.hover:before,
  .steuernews_current_issue ul li.hover:before,
  aside .steuernews_article_overview_widget ul li.hover:before,
  .sub_menu ul li.hover:before,
  .offcanvas_menu ul li:hover:before,
  .steuernews_current_issue ul li:hover:before,
  aside .steuernews_article_overview_widget ul li:hover:before,
  .sub_menu ul li:hover:before {
    background-color: #000000;
    content: "";
    display: block;
    height: 0.3125rem;
    left: 1em;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 0.9375rem;
    z-index: 9;
  }
  .offcanvas_menu ul li:active,
  .steuernews_current_issue ul li:active,
  aside .steuernews_article_overview_widget ul li:active,
  .sub_menu ul li:active,
  .offcanvas_menu ul li.active,
  .steuernews_current_issue ul li.active,
  aside .steuernews_article_overview_widget ul li.active,
  .sub_menu ul li.active {
    background-color: #40bfbf;
  }
  .offcanvas_menu ul li:active:before,
  .steuernews_current_issue ul li:active:before,
  aside .steuernews_article_overview_widget ul li:active:before,
  .sub_menu ul li:active:before,
  .offcanvas_menu ul li.active:before,
  .steuernews_current_issue ul li.active:before,
  aside .steuernews_article_overview_widget ul li.active:before,
  .sub_menu ul li.active:before {
    display: none;
  }
  .offcanvas_menu ul li a,
  .steuernews_current_issue ul li a,
  aside .steuernews_article_overview_widget ul li a,
  .sub_menu ul li a {
    display: -webkit-box;
    display: flex;
    text-decoration: none;
  }
  .offcanvas_menu ul li > ul,
  .steuernews_current_issue ul li > ul,
  aside .steuernews_article_overview_widget ul li > ul,
  .sub_menu ul li > ul {
    background-color: #fff;
    margin-bottom: 1.5em;
  }
  .offcanvas_menu ul li > ul li a,
  .steuernews_current_issue ul li > ul li a,
  aside .steuernews_article_overview_widget ul li > ul li a,
  .sub_menu ul li > ul li a {
    color: #000000;
  }
  .offcanvas_menu ul .no_li,
  .steuernews_current_issue ul .no_li,
  aside .steuernews_article_overview_widget ul .no_li,
  .sub_menu ul .no_li {
    margin-bottom: 1em;
    margin-top: 1.5em;
    padding: 2.25em 2.25em 0 2.25em;
    pointer-events: none;
    position: relative;
  }
  .offcanvas_menu ul .no_li:before,
  .steuernews_current_issue ul .no_li:before,
  aside .steuernews_article_overview_widget ul .no_li:before,
  .sub_menu ul .no_li:before {
    background-color: #fff;
    content: "";
    display: block;
    height: 0.75em;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .offcanvas_menu ul .no_li:after,
  .steuernews_current_issue ul .no_li:after,
  aside .steuernews_article_overview_widget ul .no_li:after,
  .sub_menu ul .no_li:after {
    background-color: #fff;
    bottom: -0.6em;
    content: "";
    display: block;
    height: 0.3125rem;
    position: absolute;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 0.9375rem;
    z-index: 9;
  }
  .offcanvas_menu ul .no_li span,
  .steuernews_current_issue ul .no_li span,
  aside .steuernews_article_overview_widget ul .no_li span,
  .sub_menu ul .no_li span {
    text-transform: uppercase;
  }
  .offcanvas_menu ul .no_li:first-child,
  .steuernews_current_issue ul .no_li:first-child,
  aside .steuernews_article_overview_widget ul .no_li:first-child,
  .sub_menu ul .no_li:first-child {
    margin-top: 0;
  }
  .offcanvas_menu ul .no_li:first-child:before,
  .steuernews_current_issue ul .no_li:first-child:before,
  aside .steuernews_article_overview_widget ul .no_li:first-child:before,
  .sub_menu ul .no_li:first-child:before {
    display: none;
  }
  .offcanvas_menu ul .no_li + li,
  .steuernews_current_issue ul .no_li + li,
  aside .steuernews_article_overview_widget ul .no_li + li,
  .sub_menu ul .no_li + li {
    margin-top: 1.5em;
  }
  .offcanvas_menu .direct_link.no_li,
  .steuernews_current_issue .direct_link.no_li,
  aside .steuernews_article_overview_widget .direct_link.no_li,
  .sub_menu .direct_link.no_li {
    margin-bottom: 2em;
  }
  .offcanvas_menu .direct_link.no_li > span,
  .steuernews_current_issue .direct_link.no_li > span,
  aside .steuernews_article_overview_widget .direct_link.no_li > span,
  .sub_menu .direct_link.no_li > span {
    pointer-events: auto;
  }
  .offcanvas_menu .direct_link.no_li > span > a,
  .steuernews_current_issue .direct_link.no_li > span > a,
  aside .steuernews_article_overview_widget .direct_link.no_li > span > a,
  .sub_menu .direct_link.no_li > span > a {
    padding-left: 0;
  }
  .offcanvas_menu .direct_link.no_li + li,
  .steuernews_current_issue .direct_link.no_li + li,
  aside .steuernews_article_overview_widget .direct_link.no_li + li,
  .sub_menu .direct_link.no_li + li {
    display: none;
  }
  .offcanvas_menu .direct_link.no_li:hover,
  .steuernews_current_issue .direct_link.no_li:hover,
  aside .steuernews_article_overview_widget .direct_link.no_li:hover,
  .sub_menu .direct_link.no_li:hover,
  .offcanvas_menu .direct_link.no_li:focus,
  .steuernews_current_issue .direct_link.no_li:focus,
  aside .steuernews_article_overview_widget .direct_link.no_li:focus,
  .sub_menu .direct_link.no_li:focus,
  .offcanvas_menu .direct_link.no_li:active,
  .steuernews_current_issue .direct_link.no_li:active,
  aside .steuernews_article_overview_widget .direct_link.no_li:active,
  .sub_menu .direct_link.no_li:active,
  .offcanvas_menu .direct_link.no_li.active,
  .steuernews_current_issue .direct_link.no_li.active,
  aside .steuernews_article_overview_widget .direct_link.no_li.active,
  .sub_menu .direct_link.no_li.active {
    background: #339999;
  }
  .offcanvas_menu .direct_link.no_li:hover a,
  .steuernews_current_issue .direct_link.no_li:hover a,
  aside .steuernews_article_overview_widget .direct_link.no_li:hover a,
  .sub_menu .direct_link.no_li:hover a,
  .offcanvas_menu .direct_link.no_li:focus a,
  .steuernews_current_issue .direct_link.no_li:focus a,
  aside .steuernews_article_overview_widget .direct_link.no_li:focus a,
  .sub_menu .direct_link.no_li:focus a,
  .offcanvas_menu .direct_link.no_li:active a,
  .steuernews_current_issue .direct_link.no_li:active a,
  aside .steuernews_article_overview_widget .direct_link.no_li:active a,
  .sub_menu .direct_link.no_li:active a,
  .offcanvas_menu .direct_link.no_li.active a,
  .steuernews_current_issue .direct_link.no_li.active a,
  aside .steuernews_article_overview_widget .direct_link.no_li.active a,
  .sub_menu .direct_link.no_li.active a {
    color: #000000;
  }
  .offcanvas_menu .direct_link.no_li:hover:after,
  .steuernews_current_issue .direct_link.no_li:hover:after,
  aside .steuernews_article_overview_widget .direct_link.no_li:hover:after,
  .sub_menu .direct_link.no_li:hover:after,
  .offcanvas_menu .direct_link.no_li:focus:after,
  .steuernews_current_issue .direct_link.no_li:focus:after,
  aside .steuernews_article_overview_widget .direct_link.no_li:focus:after,
  .sub_menu .direct_link.no_li:focus:after,
  .offcanvas_menu .direct_link.no_li:active:after,
  .steuernews_current_issue .direct_link.no_li:active:after,
  aside .steuernews_article_overview_widget .direct_link.no_li:active:after,
  .sub_menu .direct_link.no_li:active:after,
  .offcanvas_menu .direct_link.no_li.active:after,
  .steuernews_current_issue .direct_link.no_li.active:after,
  aside .steuernews_article_overview_widget .direct_link.no_li.active:after,
  .sub_menu .direct_link.no_li.active:after {
    background: #000000;
  }
  .offcanvas_menu .direct_link.no_li:hover:before,
  .steuernews_current_issue .direct_link.no_li:hover:before,
  aside .steuernews_article_overview_widget .direct_link.no_li:hover:before,
  .sub_menu .direct_link.no_li:hover:before,
  .offcanvas_menu .direct_link.no_li:focus:before,
  .steuernews_current_issue .direct_link.no_li:focus:before,
  aside .steuernews_article_overview_widget .direct_link.no_li:focus:before,
  .sub_menu .direct_link.no_li:focus:before,
  .offcanvas_menu .direct_link.no_li:active:before,
  .steuernews_current_issue .direct_link.no_li:active:before,
  aside .steuernews_article_overview_widget .direct_link.no_li:active:before,
  .sub_menu .direct_link.no_li:active:before,
  .offcanvas_menu .direct_link.no_li.active:before,
  .steuernews_current_issue .direct_link.no_li.active:before,
  aside .steuernews_article_overview_widget .direct_link.no_li.active:before,
  .sub_menu .direct_link.no_li.active:before {
    background-color: #fff;
    content: "";
    display: block;
    height: 0.75em;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    width: 100%;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 0.75em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 1.5em 0.75em 0;
    max-width: 8.75rem;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.75em;
  }
  .team_entry .photo {
    margin-bottom: 0.75em;
    max-width: 8.75rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.75em;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #cccccc;
    border: 0.0625rem solid #b3b3b3;
    border-radius: 0;
    bottom: 100%;
    color: #000000;
    font-size: 85%;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #cccccc;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #b3b3b3;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .address .company_name .addition {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .address .company_name .addition {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 20rem) and (max-width: 34.9375rem) {
  aside {
    margin-left: calc(-50vw + 50%);
    width: 100vw;
  }
}
@media screen and (min-width: 20rem) {
  aside .steuernews_issues .title,
  aside .steuernews_current_issue .title,
  aside .steuernews_article_overview_widget .title,
  aside .teaser_overview .teaser > a .title,
  aside .steuernews_issues h2,
  aside .steuernews_current_issue h2,
  aside .steuernews_article_overview_widget h2,
  aside .teaser_overview .teaser > a h2 {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  aside .steuernews_issues .title,
  aside .steuernews_current_issue .title,
  aside .steuernews_article_overview_widget .title,
  aside .teaser_overview .teaser > a .title,
  aside .steuernews_issues h2,
  aside .steuernews_current_issue h2,
  aside .steuernews_article_overview_widget h2,
  aside .teaser_overview .teaser > a h2 {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .button > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active > span,
  .button.active > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button:active > span,
  .button.active > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .chart_organigram {
    display: block;
    margin-bottom: 0.75em;
  }
}
@media screen and (min-width: 30rem) {
  .company_logo_wrapper .company_logo_div {
    margin-right: 2em;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo_wrapper .company_logo_div .company_logo {
    width: 3.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .company_logo_wrapper .company_logo_div .company_logo {
    width: 4.375rem;
  }
}
@media screen and (min-width: 75rem) {
  .company_logo_wrapper .company_logo_div .company_logo {
    width: 5.625rem;
  }
  .scrolled_down .company_logo_wrapper .company_logo_div .company_logo {
    width: 2.5rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo_wrapper .company_logo_div .company_logo {
    width: 5.9375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo_wrapper .company_name_div {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
            justify-content: flex-start;
    font-size: 0.9375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .company_logo_wrapper .company_name_div {
    font-size: 1rem;
  }
}
@media screen and (min-width: 75rem) {
  .company_logo_wrapper .company_name_div {
    font-size: 1.0625rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo_wrapper .company_name_div {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo_wrapper .company_name_div .company_name_connection {
    align-self: flex-end;
    margin-bottom: 1.6em;
    margin-left: 1.5em;
    margin-right: 1.5em;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo_wrapper .company_name_div .company_name .name {
    font-size: 1.0625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .company_logo_wrapper .company_name_div .company_name .name {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 75rem) {
  .company_logo_wrapper .company_name_div .company_name .name {
    font-size: 1.4375rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo_wrapper .company_name_div .company_name .name {
    font-size: 1.5625rem;
  }
}
@media screen and (min-width: 76.375rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li {
    height: calc(6.737500000000001rem + 1.53125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li {
    height: 8.26875rem;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li {
    width: calc(8.6625rem + 1.96875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li {
    width: 10.63125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .sitemap .confirm_page,
  footer .sitemap .news_entity,
  footer .sitemap .zms_folder {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
  footer .sitemap .confirm_page .wrapper_one,
  footer .sitemap .news_entity .wrapper_one,
  footer .sitemap .zms_folder .wrapper_one {
    flex-basis: 66%;
  }
  footer .sitemap .confirm_page .wrapper_two,
  footer .sitemap .news_entity .wrapper_two,
  footer .sitemap .zms_folder .wrapper_two {
    flex-basis: 33%;
  }
  footer .sitemap .confirm_page .wrapper_two .entry,
  footer .sitemap .news_entity .wrapper_two .entry,
  footer .sitemap .zms_folder .wrapper_two .entry {
    width: 100%;
  }
}
@media screen and (min-width: 20rem) {
  .file {
    height: calc(2.3913043478260874rem + 0.543478260869565
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file {
    height: 2.93478261rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    line-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    line-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    height: calc(2.3913043478260874rem + 0.543478260869565
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    height: 2.93478261rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_button > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"] {
    height: calc(2.3913043478260874rem + 0.543478260869565
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"] {
    height: 2.93478261rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 20rem) {
  button {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  button {
    height: 3.375rem;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_entry {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_entry {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_radio > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_radio > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.mobile .container {
      padding-left: max(calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75)), env(safe-area-inset-left));
      padding-right: max(calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75)), env(safe-area-inset-right));
    }
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 6) - (2.1875 / 6)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.52083333rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 6) + (2.1875 / 6)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.52083333rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  header .fixed_header .top_header_wrapper .top_header .call .text,
  header .fixed_header .top_header_wrapper .top_header .email .text {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  header .scrolled_header {
    margin-top: 10.3125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  header .scrolled_header {
    margin-top: 12.4375rem;
  }
}
@media screen and (min-width: 75rem) {
  header .scrolled_header {
    margin-top: 14.5rem;
  }
}
@media screen and (min-width: 20rem) {
  header .mobile_menu .icon {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  header .mobile_menu .icon {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  header .mobile_menu .icon {
    width: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  header .mobile_menu .icon {
    width: 3.375rem;
  }
}
@media screen and (min-width: 76.375rem) {
  header .mobile_menu {
    display: none;
  }
}
@media screen and (min-width: 30rem) {
  .vpi_diagram_grid_wrapper .vpi_diagram_label_legend_wrapper {
    padding-left: 3.75rem;
  }
}
@media screen and (min-width: 30rem) {
  .vpi_diagram_grid_wrapper .vpi_diagram text {
    font-size: 100%;
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3em;
            column-gap: 3em;
  }
}
@media screen and (max-width: 30rem) {
  figure.small_full_width {
    margin-right: 0;
    margin-top: 0;
    max-width: none;
    width: 100%!important;
  }
}
@media screen and (min-width: 61.875rem) {
  .jumbotron {
    max-width: 60%;
  }
}
@media screen and (min-width: 35rem) {
  .container {
    width: 85%;
  }
}
@media screen and (min-width: 61.875rem) {
  .container {
    width: 75%;
  }
}
@media screen and (min-width: 75rem) {
  main,
  aside {
    margin-top: 4.6875rem;
  }
}
@media screen and (min-width: 20rem) {
  .comment {
    font-size: calc(0.75rem + 0.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .comment {
    font-size: 1.625rem;
  }
}
@media screen and (min-width: 35rem) {
  .comment {
    display: inline-block;
  }
  .comment .smaller {
    font-size: 65%;
    text-transform: none;
  }
}
@media screen and (min-width: 75rem) {
  .comment {
    margin: 3em 0 1.5em 0;
  }
}
@media screen and (min-width: 30rem) {
  .newsletter_quick_subscribe form {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 20rem) {
  .newsletter_quick_subscribe input[type="email"] {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .newsletter_quick_subscribe input[type="email"] {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .newsletter_quick_subscribe button > span {
    min-height: calc(2.625rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .newsletter_quick_subscribe button > span {
    min-height: 3.25rem;
  }
}
@media screen and (min-width: 30rem) {
  .newsletter_quick_subscribe button {
    flex-shrink: 0;
    width: auto;
  }
}
@media screen and (min-width: 76.375rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 76.3125rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_content,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_content {
    padding-top: 1em;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    margin-top: 18.9rem;
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 0;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1 {
    top: 44%;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3 {
    bottom: 44%;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (max-width: 76.3125rem) and (min-width: 47.5rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    margin-top: 16rem;
  }
}
@media screen and (max-width: 76.3125rem) and (min-width: 61.875rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    margin-top: 18rem;
  }
}
@media screen and (max-width: 76.3125rem) and (min-width: 75rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    margin-top: 20.3rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    width: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    width: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_content .content {
    top: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_content .content {
    top: 3.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.5em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.5em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .slide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_issues a {
    line-height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_issues a {
    line-height: 3.375rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media print {
  .comment {
    font-weight: 700;
    margin-bottom: 0!important;
    margin-top: 3em !important;
  }
  main .hideinmain {
    display: none!important;
  }
  .company_logo {
    display: block;
    margin-bottom: 0.75em;
    max-width: 6.25rem !important;
  }
  .company_logo img {
    display: block;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
  .team_overview .entry .contact > span:first-child::after {
    content: ":  ";
  }
}
